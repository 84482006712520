import React, { useState, useRef } from "react"
import { graphql, navigate } from "gatsby"
import Layout from '../components/Layout' 
import Hero from '../components/widgets/Hero' 
import Product from '../components/products/Product' 
import BackIcon from "../assets/svg/inline/ic_chevron-down.svg"
import RadioGroup from "../components/products/RadioGroup"
import NoResultImg from "../assets/img/calculator/no-result.png"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import MaxLoanAmountResponse from '../components/calculator/MaxLoanAmountResponse'
import MinRentalIncomeResponse from '../components/calculator/MinRentalIncomeResponse'
import ResponseFooter from '../components/calculator/ResponseFooter'
import WarningIcon from "../assets/svg/inline/ic_warning.svg"
import { getPropertyType, getMaxLTV, getRate, getReversionRate, getProductFee, getMinLoan, getMaxLoan, getStressRate, getProductCriteria } from '../components/products/Product';

export default function Calculator({ location, data }) {
  const page = data.allWpPage.nodes[0];
  const products = location?.state?.products;
  const parentLink = page?.wpParent?.node?.uri;

  //for controlling form elements
//'Max loan amount','Min monthly rental income'
  const defaultFormValues = {type:'Max loan', estimatedValue:'',estimatedIncome:'',netLoanAmount:'',applicationType:'',addFeeToLoan: 'No', firstTimeLandlord: 'No', adjCommercial: 'No', higherRateTaxpayer: 'No'}
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(false);
  const [gotError, setGotError] = useState(false);
  const [showExtraQuestion, setShowExtraQuestion] = useState(false);
  const [showProductDetails, setShowProductDetails] = useState(false);
  const form = useRef();
  const results = useRef();

  /**
   * Gets a number from string input
   */
  const getNumberFromString = function(value) {
	//remove any characters we don't want
	value = value.replace(/[\D\s_]+/g, "");
	
	//cast to integer
	value = value ? parseInt( value, 10 ) : 0;
	
	return value;
  }

  /**
   * format numeric inputs with commas etc
   */
  const formatNumericInput = function(value) {
	
	value = getNumberFromString(value);
	
	//format
	return value.toLocaleString( "en-GB" );	
  }

  /**
   * Called when a form field changes
   */
  const handleChange = function(event) {

    let newValues = { ...formValues },
		value = event.target.value;
	
	//format numeric inputs
	if (event.target.className==='text') {
		value = formatNumericInput(value);
	}

	newValues[event.target.name] = value;
	
	if (event.target.name==='applicationType') {
		setShowExtraQuestion(value==='INDIVIDUAL');
	}
	
	setFormValues(newValues);
	
	setResponse(false);
	setGotError(false);
  }

  /*
   * When the form is submitted via the enter key
   */
  const handleSubmit = function(event) {
    event.preventDefault();
	submitForm();
  }

  /**
   * when the form is submitted via the submit button
   *  - we do a dummy submit for now
   */
  async function submitForm() {
	
    setLoading(true);
	setResponse(false);
	setGotError(false);
	
	//default request url is for the Minimum monthly rental income calculator
	let apiUrl = process.env.GATSBY_CALCULATOR_API_INCOME || "https://app.bbcenv.landbay.co.uk/marketingportal/affordabilityCalculator/minRentalIncome";
	
	/*
	example request body for max loan amount:
	  {
	    "productCode":"LVFJ7022003",
	    "purchasePrice": 25000000,
	    "estimatedMonthlyRent": 100000,
	    "applicationType": "LIMITED_COMPANY",
	    "addFeeToLoan": true,
	    "allApplicantsFirstTimeLandlords": false,
	    "adjCommercial": false
	  }
	
	example request body for min rental income:
	  {
	    "productCode": "LVFJ7022003",
	    "purchasePrice": 25000000,
	    "netLoanAmount": 15000000,
	    "applicationType": "LIMITED_COMPANY",
	    "addFeeToLoan": true,
	    "allApplicantsFirstTimeLandlords": false,
	    "adjCommercial": false
	  }
	*/
	let responses = [];
	for (let i=0; i<products.length; i++) {
		
		let product = products[i];
		
		//assemble the args	
		let args = {
			productCode: product.productCode,
			purchasePrice: getNumberFromString(formValues.estimatedValue)*100,
			applicationType: formValues.applicationType,
			addFeeToLoan: formValues.addFeeToLoan==='Yes',
			allApplicantsFirstTimeLandlords: formValues.firstTimeLandlord==='Yes',
			adjCommercial: formValues.adjCommercial==='Yes'
		};
		
		if (formValues.applicationType==='INDIVIDUAL') {
			args.applicantHigherRateTaxpayer = formValues.higherRateTaxpayer==='Yes';
		}
		
		if (formValues.type==='Max loan') {
			apiUrl = process.env.GATSBY_CALCULATOR_API_LOAN || "https://app.bbcenv.landbay.co.uk/marketingportal/affordabilityCalculator/maxLoanAmount";
			
			args.estimatedMonthlyRent = getNumberFromString(formValues.estimatedIncome)*100;
		} else {		
			args.netLoanAmount = getNumberFromString(formValues.netLoanAmount)*100;
		}
		
		const response1 = await sendRequest(apiUrl, args);
		
		responses.push(response1);
	}
	
	setResponse(responses);
	setLoading(false);
	scrollToResults();
  }

  async function sendRequest(url, args) {
	if (process.env.NODE_ENV==='development') {
		if (formValues.type==='Max loan') {			
			return {"productCode": null,"maxLoanAmount": 187500000,"productFeeAmount": 3750000,"minICR": 1.25,"adminFee": 25000,"valuationFee": null,"isProductAvailable": true,"failureContext": null,"failureReasons": [],"ICR": 4.6545};
			//return {"productCode":null,"maxLoanAmount":null,"productFeeAmount":null,"minICR":1.25,"adminFee":null,"valuationFee":null,"isProductAvailable":false,"failureContext":"Max loan amount calculated based on rental income is £12,015.00","failureReasons":["Landbay minimum loan amount is £30,000","Product minimum loan amount is £100,000.00"],"ICR":0.2002};
		} else {
			return {"minRentalIncome":83300,"productFeeAmount":300000,"maxLTV":0.750000,"adminFee":19900,"valuationFee":105000,"isProductAvailable":true,"failureReasons":[],"LTV":0.0770};
			//return {"minRentalIncome":null,"productFeeAmount":null,"maxLTV":0.750000,"adminFee":null,"valuationFee":null,"isProductAvailable":false,"failureReasons":["Our maximum LTV is 85%","Product maximum LTV is 75%"],"LTV":1.0000};
		}
	} else {
	    try {
	      const response1 = await fetch(url, {
	        method: 'POST',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json'
	        },
	        body: JSON.stringify(args)
	      });
	
		  if (response1.ok) {
			const content = await response1.json();
			//handleResponse(content);
			return content;
		  }	else {
			setGotError(true);
		  }	
	    } catch (err) {
		  setGotError(true);
	      console.log('caught error');
	      console.log(err);
	    }
    }
  }

  const scrollToResults = function() {
	if (window && document && window.innerWidth<768) {	
	  window.scrollTo({
		top: results.current.offsetTop,
		behavior: 'smooth',
	  });
    }
  }

  const scrollToForm = function() {
	if (window && document && window.innerWidth<768) {	
	  window.scrollTo({
		top: form.current.offsetTop,
		behavior: 'smooth',
	  });
    }
  }
  
  const goBack = function() {
    if (window && window.history.length > 2) {
        // if history is not empty, go back:
        navigate(-1);
    } else {
        // go to specified fallback url:
        window.history.replaceState(null, null, parentLink);
    }
}

  return (
    <Layout className="calculator">	
      <Seo post={page} />
	  <div className="page-widgets">
	    <Hero widget={{layout : 'mini'}} />
	  </div>
	  <article className="internal">
		<header>
			{
			  parentLink
			  ? /*<p className="back"><Link to={parentLink}><BackIcon /><span className="hide-mobile">Back to our </span>products</Link></p>*/
			  	<p className="back"><button type="button" className="plain" onClick={() => goBack()}><BackIcon /><span className="hide-mobile">Back to our </span>products</button></p>
			  : null
			}
			<h1>{page.title}</h1>
		</header>
		{
		  products
		  ? <>
				{products.length===1 && (
					<>
						<Product product={products[0]} addToCompare={false} selectedForCompare={false} calculatorLink={false} />
						<div className="mobile-product">
							<p className="product-code">{products[0].productCode}</p>
							<button type="button" className="plain" onClick={() => setShowProductDetails(true)}>View details</button>
						</div>
						{showProductDetails && (
							<div className="mobile-product-details">
								<div className="top">								
									<div className="top2">								
										<button type="button" className="button solid black small" onClick={() => setShowProductDetails(false)}>Close</button>
									</div>
								</div>
								<div className="bottom">								
									<div className="bottom2">								
										<p className="product-code">{products[0].productCode}</p>
										<h3>{products[0].productName}</h3>
										<table>
											<tbody>
												<tr>
													<th>Property type</th>
													<td>{getPropertyType(products[0])}</td>
												</tr>
												<tr>
													<th>Product type</th>
													<td>{products[0].productType}</td>
												</tr>
												<tr>
													<th>Max LTV</th>
													<td>{getMaxLTV(products[0])}</td>	
												</tr>
												<tr>
													<th>Rate</th>
													<td dangerouslySetInnerHTML={getRate(products[0])} />	
												</tr>
												<tr>
													<th>Reversion rate</th>
													<td dangerouslySetInnerHTML={getReversionRate(products[0])} />
												</tr>
												<tr>
													<th>Product fee</th>
													<td>{getProductFee(products[0])}</td>
												</tr>
												<tr>
													<th>Min loan</th>
													<td>&pound;{getMinLoan(products[0])}</td>	
												</tr>
												<tr>
													<th>Max loan</th>
													<td>&pound;{getMaxLoan(products[0])}</td>
												</tr>
												<tr>
													<th>Stress Rate</th>
													<td>{getStressRate(products[0])}</td>
												</tr>
											</tbody>
										</table>
										<h4>Product criteria</h4>
										{getProductCriteria(products[0])}
									</div>
								</div>
								
							</div>
						)}
					</>
				)}
				<div className="columns">
					<form onSubmit={handleSubmit} ref={form}>
		
						<div className="bottom">
							<RadioGroup groupname="type" options={['Max loan','Min rent']} value={formValues.type} onChange={handleChange} />
							
							<div className="cols">
								<div className="col1">
									<p>
										<label htmlFor="input1">Estimated property value</label>
										<span className="value-input">
											<input type="text" id="input1" name="estimatedValue" className="text" value={formValues.estimatedValue} placeholder="Enter" onChange={handleChange} required />
										</span>
									</p>
									{
										formValues.type==='Max loan'
										? <p>
											<label htmlFor="input2">Estimated monthly rental income</label>
											<span className="value-input">
												<input type="text" id="input2" name="estimatedIncome" className="text" value={formValues.estimatedIncome} placeholder="Enter" onChange={handleChange} required />
											</span>
										  </p>
										: <p>
											<label htmlFor="input2b">Net loan amount</label>
											<span className="value-input">
												<input type="text" id="input2b" name="netLoanAmount" className="text" value={formValues.netLoanAmount} placeholder="Enter" onChange={handleChange} required />
											</span>
										  </p>
									}					
									<p>
										<label htmlFor="input3">Applicant type</label>
										<select name="applicationType" value={formValues.applicationType} onChange={handleChange} required>
											<option value="" aria-label="Please choose an option">Select</option>
											<option value="INDIVIDUAL">Individual</option>
											<option value="LIMITED_COMPANY">Limited Company</option>
											<option value="PARTNERSHIP">Partnership</option>
										</select>
									</p>
								</div>
								<div className="col2">
									{
										showExtraQuestion
										? <RadioGroup label="Are any of the applicants higher rate taxpayers?" labelMobile="Applicant(s) is a high rate taxpayer" groupname="higherRateTaxpayer" options={['Yes','No']} value={formValues.higherRateTaxpayer} onChange={handleChange} className="small" />
										: null
									}
									<RadioGroup label="Add product fee to loan?" labelMobile="Add product fee" groupname="addFeeToLoan" options={['Yes','No']} value={formValues.addFeeToLoan} onChange={handleChange} className="small" />
									<RadioGroup label="Are all of the applicants first time landlords?" labelMobile="Applicant(s) is a first time landlord" groupname="firstTimeLandlord" options={['Yes','No']} value={formValues.firstTimeLandlord} onChange={handleChange} className="small" />
									<RadioGroup label="Is the property above or adjacent to commercial premises?" labelMobile="Property is above or adjacent to commercial" groupname="adjCommercial" options={['Yes','No']} value={formValues.adjCommercial} onChange={handleChange} className="small" />
								</div>
							</div>
							
						</div>
						<p className="buttonp">
							<button type="submit" className="button solid black">Calculate affordability</button>
						</p>
					</form>
					<section className="result" ref={results}>
						{!loading && !response && !gotError && (
							<>
							  {
								products.length===1
								? <div className="no-result single">
									<img src={NoResultImg} alt="" />
									{
									  formValues.type==='Max loan'
									  ? <p>Use our calculator to find out how much you can borrow</p>
									  : <p>Use our calculator to find out your minimum monthly rental income</p>
									}
								</div>
								: <div className="no-result">
									<h2>Selected Products</h2>
									<ul className="product-list">
									  {products.map((product, i) => {
										return (<li key={`product${i}`}>
											<div className="landbay-product mini">
												<h3>{product.productName}</h3>
												<p className="product-rate">Rate: <strong dangerouslySetInnerHTML={getRate(product)} /></p>
												<p className="product-code">{product.productCode}</p>
											</div>
										</li>);
									  })}
									</ul>
								</div>
							  }		
							</>					
						)}
						
						{/*loading animation?*/}
						{loading && (
							<div className="loading"></div>
						)}
						
						{gotError && (
							<div className="got-error">
							  <p><WarningIcon /></p>
							  <p>There was a technical error when running the calculation</p>
							  <p>Please try again later</p>
							</div>
						)}
						
						{
						  (response && !gotError)
						  ? <div className="got-result">
						  	  <h2>Result <button type="button" className="plain" onClick={scrollToForm}>Edit</button></h2>
							  <ul className="result-list">
						  	    {response.map((response1, i) => {
								  return (<li key={`result${i}`}>
									{
									  formValues.type==='Max loan'
									  ? <MaxLoanAmountResponse response={response1} product={products[i]} />
									  : <MinRentalIncomeResponse response={response1} product={products[i]} />
									}
									</li>);
								  })}
							  </ul>
							  <ResponseFooter page={page} parentLink={parentLink} scrollToForm={scrollToForm} goBack={goBack} />
							</div>
						  : null
						}
						
					</section>
				</div>
			</>
		  : <>
			  <p className="no-product">No product selected. Please choose a product from our products page to use the calculator</p>
			  {
				parentLink
				? <p className="buttonp"><button type="button" className="button purple solid" onClick={() => goBack()}>Our products</button></p>
				: null
			  }
			</>
		}
		
	  </article>
    </Layout>
  )
}
export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        content
	    calculator {
	      lendingCriteriaLink
	      dipLink
	    }
		wpParent {
          node {
            uri
          }
        }
        ...PageSEOFragment
      }
    }
  }
`