import React from "react";
import { Link } from "gatsby"
import Button from "../Button"
import BackIcon from "../../assets/svg/inline/ic_chevron-down.svg"

export default function ResponseFooter({page, parentLink, scrollToForm, goBack}) {
	
  const dipLink = page?.calculator?.dipLink ? page.calculator.dipLink : '/';
  const lendingCriteriaLink = page?.calculator?.lendingCriteriaLink ? page.calculator.lendingCriteriaLink : '/';	

  /**
   * Print the current screen
   */
  const print = function() {
	if (window) {
		window.print();
	}
  }

  return(
	<>
	  <p className="remember">Remember to check the product criteria and our general <Link to={lendingCriteriaLink}>lending criteria</Link> before starting a DIP to ensure the applicant is eligible for the product.</p>
	  <div className="buttons">
		  {
		    parentLink
		    ? <p className="back"><button type="button" className="plain" onClick={() => goBack()}><BackIcon />Back to our products</button></p>
		    : null
		  }
		  <p><Button link={{url: dipLink, title: 'Start a DIP', target: '_blank'}} className="solid purple" /></p>
		  <p><button type="button" className="button solid black" onClick={print}>Print</button></p>
		  <p><button type="button" className="button line black" onClick={scrollToForm}>Back to top</button></p>
	  </div>
  </>
  )
}


