import React, { useState } from "react";
import {floatToPercentage, penniesToPounds} from '../../utils/formatNumbers'
import ErrorIcon from "../../assets/svg/inline/ic_error.svg"
import InfoIcon from "../../assets/svg/inline/ic_info.svg"
import CloseIcon from "../../assets/svg/inline/ic_close.svg"
import { getRate } from '../products/Product';

export default function MaxLoanAmountResponse({response, product}) {
	
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  
  const meetsRequirements = function() {
	return (response.ICR>=response.minICR);
  }

  return(
	<div className="calculator-result">
		<div className="top">
			<h3>{product.productName}</h3>
			<p className="product-rate">Rate: <strong dangerouslySetInnerHTML={getRate(product)} /></p>
			<p className="product-code">{product.productCode}</p>
	  	</div>
	  {
		response.failureReasons?.length>0
		? <div className="bottom failed">
			<div className="errors">
				{
				  response.failureContext
				  ? <p className="context">{response.failureContext}</p>
				  : null
				}
				{response.failureReasons.map((message, i) => (
				  <p key={`message${i}`}>{message}</p>							    		
				))}
				<p className="title"><ErrorIcon />Based on the inputs provided, we cannot lend to you on this product</p>
			  </div>
		  </div>
		: <div className="bottom">
			{
			  (response.ICR && response.minICR)
			  ? <>
				  <p className="icr-ltv">
				    Based on the max possible loan amount, 
				    <strong> ICR is {floatToPercentage(response.ICR)}</strong>, 
				    which is 
				    {
					  meetsRequirements()
					  ? <strong className="good"> higher </strong> 
					  : <strong className="bad"> lower </strong> 
				    }
				    than the <strong>minimum ICR</strong> ({floatToPercentage(response.minICR)}).
				  </p>
		
				  <div className="max-amount">
				  	<p className="maximum"><button type="button" className="plain" onClick={() => setShowInfoPopup(true)}>Maximum net loan<InfoIcon /></button></p> 	
			    	<p className="amount">{penniesToPounds(response.maxLoanAmount)}</p>
			    	{showInfoPopup && (
						<div class="info-popup">
							<p>The maximum loan amount before any fees are added</p>
							<button type="button" className="plain" onClick={() => setShowInfoPopup(false)}><CloseIcon /></button>
						</div>
					)}
			  	  </div>
				  <div className="summary">
				  	<p>Product fee: <strong>{penniesToPounds(response.productFeeAmount)}</strong></p>
				  	<p>Admin fee: <strong>{penniesToPounds(response.adminFee)}</strong></p>
				  	<p>Valuation fee: <strong>{response.valuationFee === null ? "Quote required" : penniesToPounds(response.valuationFee)}</strong></p>
			  	  </div>
				</>
			  : null
			}
	
		  </div>
	  }							  
	</div>
  )
}


