import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import ChevronIcon from "../../assets/svg/inline/ic_chevron-down.svg"
import Collapse from "@kunukn/react-collapse";
import {abbreviateNumber, floatToPercentage, penniesToPounds} from '../../utils/formatNumbers' 

export const getPropertyType = function(product) {
  return product.propertyCategories?.join(", ")
}

export const getMaxLTV = function(product) {
  return floatToPercentage(product.maxLtv);
}

export const getRate = function(product) {
  let rate = '';
  
  if (product.rate!==null) {//some products have 'null' for the rate	
	rate += floatToPercentage(product.rate);
  }
  if (product.hasOwnProperty('baseRate') && product.baseRate!==null && product.baseRate!=='') {
	rate += '<span> + '+product.baseRate+'</span>';
  }	
  return {__html: rate};
}

export const getReversionRate = function(product) {
  let reversionRate = '-';
  
  if (product.reversionRate!==null) {//some products have 'null' for the reversionRate	
	reversionRate = floatToPercentage(product.reversionRate);
  }
  if (product.hasOwnProperty('reversionBaseRate') && product.reversionBaseRate!==null && product.reversionBaseRate!=='') {
	reversionRate += '<span> + '+product.reversionBaseRate+'</span>';
  }	
  return {__html: reversionRate};
}

export const getStressRate = function(product) {
  if (product.stressRate===null) {
	return '';//some products have 'null' for the rate	
  }
  return floatToPercentage(product.stressRate);
}

export const getProductFee = function(product) {
  if (product.productFeeFixed) {
	return penniesToPounds(product.productFeeFixed);
  }
  if (product.productFeePerc) {
	return floatToPercentage(product.productFeePerc);
  }
  return'£0'; 
}

export const getMinLoan = function(product) {
  return abbreviateNumber(product.minLoan/100);
}

export const getMaxLoan = function(product) {
  return abbreviateNumber(product.maxLoan/100);
}	

/**
 * Print the product criteria which consists of: 
 * the ERCs, product rules, new build criteria and then expat criteria
 */
export const getProductCriteria = function(product) {
  return (	
	<ul>
	  <li>{getERCs(product)}</li>
	  {getProductRules(product)}
	  {getNewBuildCriteria(product)}
	  {getExpatCriteria(product)}
	</ul>
  );				
}	
				
const getProductRules = function(product) {
  if (product.productRules?.length) {
    return (
	  <>
		{product.productRules.map((criteria, i) => (
			<li key={`criteria${i}`}>{criteria.ruleDescr}</li>
	    ))}
	  </>
    )
  }
  return null;
}	

const getERCs = function(product) {
  if (product.ERCs?.length) {
	let output = 'ERCs: ';
	
	product.ERCs.forEach(function({rate, term}) {
	  let numYears = Math.floor(term/12);
	  for (let i=0; i<numYears; i++) {
		if (output!=='ERCs: ') {
			output += ', ';
		}
		output += floatToPercentage(rate);
	  }
	});
	return output;
  }
	
  return 'No ERCs';
}	

const getNewBuildCriteria = function(product) {
  if (product.newBuild) {
	if (product.newBuild==='NO_NEW_BUILDS') {
	  return (<li>No Newbuilds</li>)	
	}
	if (product.newBuild==='NEW_BUILDS_ONLY') {
	  return (<li>Only Newbuilds</li>)	
	}
	if (product.newBuild==='NEW_BUILDS_PERMITTED') {
	  return (<li>Newbuilds permitted</li>)	
	}
  }
	
  return null;
}	

const getExpatCriteria = function(product) {
  if (product.expats) {
	if (product.expats==='NO_EXPATS') {
	  return (<li>No Expats</li>)	
	}
	if (product.expats==='EXPATS_ONLY') {
	  return (<li>Only Expats</li>)	
	}
	if (product.expats==='EXPATS_PERMITTED') {
	  return (<li>Expats permitted</li>)	
	}
  }
	
  return null;
}	

export default function Product({product, addToCompare, selectedForCompare, calculatorLink}) {
	
  const [criteriaOpen, setCriteriaOpen] = useState(false);
  const toggleCriteria     	    	    = () => setCriteriaOpen(previousState => !previousState);

  //when product changes close the criteria
  useEffect(() => {
	setCriteriaOpen(false);
  }, [product]);

  return(
	  <div className="landbay-product">
		<div className="product-top">
			{
			  addToCompare
			  ? <p className="compare"><button className={selectedForCompare ? 'plain active' : 'plain'} type="button" onClick={() => addToCompare(product)}>Compare</button></p>
			  : null
			}
			<p className="product-code">Product Code: <strong>{product.productCode}</strong></p>
			
			{product.productTags?.length>0 && (
			  <div className="product-tags">
				{product.productTags.map((tag, i) => (					    
			  	  <p key={`tag${i}`} className="product-tag">{tag}</p>
				))}
			  </div>
			)}
					
			<h3>{product.productName}</h3>
		</div>
		<div className="product-info">
			<div className="scroller">
				<dl>
	  				<div className="width1">
						<dt>Property type</dt>
						<dd>{getPropertyType(product)}</dd>
					</div>
	  				<div className="width2">
						<dt>Product type</dt>
						<dd>{product.productType}</dd>
					</div>
	  				<div className="width3">
						<dt>Max LTV</dt>
						<dd>{getMaxLTV(product)}</dd>
					</div>
	  				<div className="width4">
						<dt>Rate</dt>
						<dd dangerouslySetInnerHTML={getRate(product)} />
					</div>
	  				<div className="width5">
						<dt>Reversion rate</dt>
						<dd dangerouslySetInnerHTML={getReversionRate(product)} />
					</div>
	  				<div className="width6">
						<dt>Product fee</dt>
						<dd>{getProductFee(product)}</dd>
					</div>
	  				<div className="width7">
						<dt>Min loan</dt>
						<dd>&pound;{getMinLoan(product)}</dd>
					</div>
	  				<div className="width8">
						<dt>Max loan</dt>
						<dd>&pound;{getMaxLoan(product)}</dd>
					</div>
	  				<div className="width9">
						<dt>Stress Rate</dt>
						<dd>{getStressRate(product)}</dd>
					</div>
				</dl>
			</div>
			<div className="product-bottom">
				<p className="product-criteria-button"><button className={criteriaOpen ? 'plain active' : 'plain'} type="button" onClick={toggleCriteria}>Product criteria<ChevronIcon /></button></p>
				{
				  calculatorLink
				  ? <Link to={calculatorLink} state={{ products: [product] }} className="button line black small">BTL Calculator</Link>
				  : null
				}
			</div>
		</div>
		<Collapse isOpen={criteriaOpen} className="product-criteria">	
			<div className="product-criteria2">
				<h4>Product criteria</h4>
				{getProductCriteria(product)}
			</div>
		</Collapse>
	</div>
  )
}


