import React from "react";

export default function RadioGroup({label, labelMobile, groupname, options, value, onChange, className}) {

  return(
	<p className={className ? `radio-group ${className}` : 'radio-group'}>
		{
		  label
		  ? <>
		  		<span className="label">{label}</span>	
		  		<span className="label-mobile">{labelMobile ? labelMobile : label}</span>	
		  	</>
		  : null
		}
		<span className="options">
			{options.map((option, i) => (
				<label 
					key={`radio${i}`} 
					className={value===option ? "active" : ""} 
					role="presentation"><input type="radio" name={groupname} value={option} className="visuallyhidden" onChange={onChange} checked={value===option ? "checked" : ""} /><span className="text">{option}</span></label>
		    ))}	
		</span>						
	</p>
  )
}

