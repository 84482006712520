
/**
 * Format supplied number using abbreviations:
 * 10000000 = 10M
 * 12000 = 12k
 */
export const abbreviateNumber = function(number) {
		
	const SI_SYMBOL = ["", "k", "M", "B", "T", "P", "E"];
	
    // what tier? (determines SI symbol)
    let tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if (tier === 0) {
		return number;
	}

    // get suffix and determine scale
    let suffix = SI_SYMBOL[tier];
    let scale = Math.pow(10, tier * 3);

    // scale the number
	let scaled = number / scale;
	// format number and add suffix
	if (number%scale === 0) {
		return scaled + suffix;
	} else {
		//this is to prevent 999k being displayed as 100.0k
		if (((scaled.toFixed(1)*10)%10)===0) {
			return scaled.toFixed(0) + suffix;
		}
    	return scaled.toFixed(1) + suffix;
	}	
}

/**
 * Formats a float like 0.0345 as a percentage like 3.45
 * Includes a fix for javascript precision issue
 */
export const floatToPercentage = function(number) {
  if (number===null) {
	return '';//some products have 'null' for the some fields	
  }
  number = number*100;
  //this is to get round the javascript precision bug where 0.034*100 = 3.4000000000000004
  //ref: https://stackoverflow.com/questions/21472828/javascript-multiplying-by-100-giving-weird-result
  if (countDecimals(number)>0) {
	number = number.toFixed(2);
  }
  if (number.toString().indexOf('.00')!==-1) {
	number = parseInt(number.split(".")[0]);
  }
  return number+'%';
}

/**
 * count the number of decimal places in a float
 */
const countDecimals = function(value) {
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0; 
}


/**
 * formats a numebr like 10000000 to 10,000,000
 */
export const numberWithCommas = function(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Converts an amount in pennies to pounds
 */
export const penniesToPounds = function(number) {
	
  number = number/100;
  if (countDecimals(number)>0) {
	number = number.toFixed(2);
  }

  return '£'+numberWithCommas(number);
}