import React from "react";
import {floatToPercentage, penniesToPounds} from '../../utils/formatNumbers'
import ErrorIcon from "../../assets/svg/inline/ic_error.svg"
import { getRate } from '../products/Product';

export default function MinRentalIncomeResponse({response, product}) {
	
  const meetsRequirements = function() {
	return (response.LTV<=response.maxLTV);
  }

  return(
	<div className="calculator-result">
		<div className="top">
			<h3>{product.productName}</h3>
			<p className="product-rate">Rate: <strong dangerouslySetInnerHTML={getRate(product)} /></p>
			<p className="product-code">{product.productCode}</p>
	  	</div>
	  	<div className={response.failureReasons?.length>0 ? 'bottom failed' : 'bottom'}>
		  {
			  (response.LTV && response.maxLTV)
			  ? <p className="icr-ltv">
				  <strong>LTV is {floatToPercentage(response.LTV)}</strong>, 
				  which is 
				  {
					meetsRequirements()
					? <strong className="good"> lower </strong> 
					: <strong className="bad"> higher </strong> 
				  }
				  than the <strong>maximum LTV</strong> ({floatToPercentage(response.maxLTV)})</p>
			  : null
		  }
		
		  {
			response.isProductAvailable && response.minRentalIncome
			? <>
				<div className="max-amount">
			    	<p className="maximum">Min monthly rental income required</p>
			    	<p className="amount">{penniesToPounds(response.minRentalIncome)}</p>
			  	  </div>
				  <div className="summary">
				  	<p>Product fee: <strong>{penniesToPounds(response.productFeeAmount)}</strong></p>
				  	<p>Admin fee: <strong>{penniesToPounds(response.adminFee)}</strong></p>
				  	<p>Valuation fee: <strong>{response.valuationFee === null ? "Quote required" : penniesToPounds(response.valuationFee)}</strong></p>
			  	  </div>
		  	  </>
			: <div className="errors">
				{
				  response.failureContext
				  ? <p className="context">{response.failureContext}</p>
				  : null
				}				
				{response.failureReasons.map((message, i) => (
				  <p key={`message${i}`}>{message}</p>							    		
				))}
				<p className="title"><ErrorIcon />Based on the inputs provided, we cannot lend to you on this product</p>
			  </div>
		  }
	  	</div>
    </div>
  )
}


